import React from 'react';
import { CARBS, COLOR_CALORIES, COLOR_CARBS, COLOR_FAT, COLOR_FIBER, COLOR_PROTEINS, FAT, FIBER, PROTEIN,TITLE_CARBS, TITLE_FAT, TITLE_FIBER, TITLE_PROTEINS, TITLE_CALORIES } from '../../lib/const';
import "./foodResume.css"

const FoodResume = props => {

    const {
        type,
        qte,
    } = props

    const title = () => {
        switch (type) {
            case CARBS: 
            return TITLE_CARBS;
            case PROTEIN: 
            return TITLE_PROTEINS;
            case FAT: 
            return TITLE_FAT;
            case FIBER: 
            return TITLE_FIBER;
            default: 
            return TITLE_CALORIES;
        }
    }

    const icons = () => {
        switch (type) {
            case CARBS: 
            return <span className="material-icons" style = {{color: COLOR_CARBS}}>cookie</span>;
            case PROTEIN: 
            return <span className="material-icons" style = {{color: COLOR_PROTEINS}}>egg_alt</span>;
            case FAT: 
            return <span className="material-icons" style = {{color: COLOR_FAT}}>water_drop</span>;
            case FIBER: 
            return <span className="material-icons" style = {{color: COLOR_FIBER}}>pix</span>;
            default: 
            return <span className="material-icons" style = {{color: COLOR_CALORIES}}>local_fire_department</span>;
        }
    }

    return (
        <div className="FoodResume">
            {icons()}
            <div className="name">{title()}</div>
            <div className="sum">{qte} gr</div>
        </div>
    )
}

export default FoodResume