import React from 'react';
import "./NoData.css"
import { COLOR_PROTEINS } from '../../lib/const';


const NoData = props => {

    return (
        <div className="NoData">
            <span className="material-icons" style={{ fontSize: 144, color: COLOR_PROTEINS }}>not_interested</span>
            <div className="NoData_title">Pas de données sur ce jour</div>
        </div>
    )
}

export default NoData