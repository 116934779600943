import React from 'react';
import Pie from '../lib/pie';
import "./food.css"


const Food = props => {

    const {
        name,
        brand,
        qte, 
        unit,
        cal,
    } = props

    return (
        <div className="Food">
            <div className="left">
                <div className="name">{name}</div>
                {brand && (
                    <div className="brand">({brand})</div>
                )}
            </div>
            <div className="right">
                <div className="qte">{parseInt(qte)} {unit}</div>
                <div className="kcal">({parseInt(cal)}Kcal)</div>
            </div>
        </div>
    )
}

export default Food