import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { requestResume } from "../sagas/resume";
import '../App.css';
import ListDays from '../organismes/ListDays';
import Page from '../organismes/Page';


const FitbitResume = props => {

    const {
        resume,
        getResume,
    } = props

    useEffect(() => {
        getResume()
    }, []);

    if (resume.isLoading || resume.data.length === 0)
        return (
            <div>is Loading</div> //TODO: page de loading
        )

    return (
        <div className="FitbitResume">
            <ListDays resume={resume} />
            <Page resume={resume} />
        </div>
    )
}

const mapStateToProps = state => ({
    resume:      state.resume, 
})

const mapDispatchToProps = dispatch => ({
    getResume: () =>     dispatch(requestResume()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FitbitResume)