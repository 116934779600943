import React, { useEffect } from 'react';
import './Activity.css';


const Activity = props => {

    const { resume } = props 

    if(undefined == resume)
        return null

    return (
        <div className='Activity'>
            <div className='Activity_activity'>
                <span className="material-icons">local_fire_department</span>
                <div className='text'>{resume.activity.value}</div>
            </div>
            { resume.body &&(
                <div className='Activity_weight'>
                    <span className="material-icons">monitor_weight</span>
                    <div className='text'>
                        {resume.body.weight} 
                        {resume.body.fat ? '(' + parseFloat(resume.body.fat).toFixed(1) + '%)' : ''}
                    </div>
                </div>
            )}
            <div className='Activity_total'>
                <span className="material-icons">restaurant</span>
                <div className='text'>{resume.calories.value}</div>
            </div>
        </div>
    )
}

export default Activity