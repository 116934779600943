import { configureStore } from '@reduxjs/toolkit';
import foodReducer from '../reducers/food';
import bodyReducer from '../reducers/body';
import activityReducer from '../reducers/activity';
import caloriesReducer from '../reducers/calories';
import sleepReducer from '../reducers/sleep';
import resumeReducer from '../reducers/resume';
import resumeSelected from '../reducers/selected';

export const store = configureStore({
  reducer: {
    food:     foodReducer,
    body:     bodyReducer,
    activity: activityReducer,
    calories: caloriesReducer,
    sleep:    sleepReducer,
    resume:    resumeReducer,
    selected:    resumeSelected,
  },
});
