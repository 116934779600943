import React from 'react';
import Food from '../../atoms/food/food';
import FoodResume from '../../atoms/foodResume/foodResume';
import { CALORIES, CARBS, PROTEIN, FAT, FIBER } from '../../lib/const';
import { nutritionalSum } from '../../lib/helper';
import './meal.css';


const Meal = props => {

    const {
        title,
        foods
    } = props

    return (
        <div className="Meal">
            <div className="Meal_Title">{title}</div>
            <div className="Meal_Details">
                {foods.map((food, index) => (
                    <Food
                        key={index}
                        name={food.loggedFood.name}
                        brand={food.loggedFood.brand}
                        qte={food.loggedFood.amount}
                        unit={parseInt(food.loggedFood.amount) === 1 ? food.loggedFood.unit.name : food.loggedFood.unit.plural}
                        cal={food.loggedFood.calories}
                    />          
                ))}
            </div>
            <div className="Meal_Resume">
                <FoodResume 
                    type={CALORIES} 
                    qte={nutritionalSum(foods, CALORIES)} />
                <FoodResume
                    type={CARBS} 
                    qte={nutritionalSum(foods, CARBS)} />
                <FoodResume 
                    type={PROTEIN} 
                    qte={nutritionalSum(foods, PROTEIN)} />
                <FoodResume 
                    type={FAT} 
                    qte={nutritionalSum(foods, FAT)} />
                <FoodResume 
                    type={FIBER} 
                    qte={nutritionalSum(foods, FIBER)} />
            </div>
        </div>
    )
}

export default Meal