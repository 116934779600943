import React from 'react';
import './circles.css';
import Circle from '../../atoms/circle/circle';
import { COLOR_CARBS, COLOR_FAT, COLOR_FIBER, COLOR_PROTEINS, TITLE_CARBS, TITLE_FAT, TITLE_FIBER, TITLE_PROTEINS } from '../../lib/const';


const Circles = props => {

    const { summary } = props 

    if(undefined === summary)
        return null

    return (
        <div className="Circles">
            <Circle title={TITLE_CARBS} color={COLOR_CARBS} consumed={parseInt(summary.carbs)} required={280} />
            <Circle title={TITLE_PROTEINS} color={COLOR_PROTEINS} consumed={parseInt(summary.protein)} required={127} />
            <Circle title={TITLE_FAT} color={COLOR_FAT} consumed={parseInt(summary.fat)} required={83} />
            <Circle title={TITLE_FIBER} color={COLOR_FIBER} consumed={parseInt(summary.fiber)} required={30} />
        </div>
    )
}

export default Circles