import React, { useEffect } from 'react';
import { connect } from "react-redux";
import SELECTED from "../actions/selected"
import uniqid from 'uniqid';
import '../App.css';
import { requestFood } from '../sagas/food';


const ListDays = props => {

    const {
        resume,
        setSelected,
        selectDay,
        selected,
    } = props

    useEffect(() => {
        select(0, resume.data[0].date);
    }, []);

    const select = (index, date) => {
        setSelected(index)
        selectDay(date)
    }

    
    

    return (
        <div className="listSquareDay">
            {resume.data?.map((day, index) => {
                var d = new Date(day.date)
                console.log()
                if (day.calories.value === "0") {
                    return (
                        <div key={uniqid()} className="NoDataDay"></div>
                    )
                }
                return (
                    <div className={"SquareDay" + (index === selected.data ? " selected" : "") + (day.calories.value === 0 ? " nodata" : "") } onClick={() => select(index, day.date)} key={uniqid()}>
                        <div className="day">{d.toLocaleDateString("fr-FR", { weekday: 'long' })}</div>
                        <div className="number">{d.getDate()}</div>
                        <div className="mounth">{d.toLocaleDateString("fr-FR", { month: 'long' })}</div>
                    </div>
                )
            }
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    selected:      state.selected, 
})

const mapDispatchToProps = dispatch => ({
    setSelected: (index) => dispatch({
        type: SELECTED.LOAD_SUCCESS,
        selected: index,
        isError: false,
    }),
    selectDay: (date) => dispatch(requestFood(date)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListDays)