import React, { useEffect } from 'react';
import { connect } from "react-redux";
import '../App.css';
import { requestFood } from "../sagas/food"
import Circles from '../molecules/Circles/circles';
import Activity from '../molecules/Activity/Activity';
import FoodDay from '../molecules/FoodDay/FoodDay';
import NoData from '../atoms/NoData/NoData';


const Page = props => {

    const {
        resume,
        selected,
        food,
        getResume,
    } = props

    if (food.isLoading)
        return null

    if (food.isError || food.data === [])
        return (
            <NoData />
        )

    return (
        <div className="Page">
            <Circles summary={food.data.summary} />
            <Activity resume={resume.data[selected.data]} />
            <FoodDay foods={food.data.foods} />
        </div>
    )
}

const mapStateToProps = state => ({
    selected:      state.selected, 
    food:      state.food, 
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Page)