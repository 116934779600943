import axios from "axios";
import FOOD from "../actions/food";

export const requestFood = (data) => async (dispatch) => {
  dispatch({
    type: FOOD.LOAD,
  });
  
  try {
    const json = await axios.get("data/food/" + data + ".json");
    dispatch({
      type: FOOD.LOAD_SUCCESS,
      foodDay: json.data,
      isError: false,
    });
  } catch (e) {
    dispatch({
      type: FOOD.ERROR,
      foodDay: [],
      isError: true,
    });
  }
};