import React from "react";

const cleanPercentage = (percentage, _tooHigh = true) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = _tooHigh && percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }) => {
  const r = 50;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={140}
      cy={60}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={"0.5rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const Text = ({ percentage }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"1.5em"}
    >
      {percentage.toFixed(0)}%
    </text>
  );
};

const Pie = ({ percentage, colour }) => {
    const pct = cleanPercentage(percentage);
    const textPct = cleanPercentage(percentage, false);
    return (
        <svg width={120} height={120}>
        <g transform={`rotate(-90 ${"100 100"})`}>
            <Circle colour="lightgrey" />
            <Circle colour={colour} pct={pct} />
        </g>
        <Text percentage={textPct} />
        </svg>
    );
};

export default Pie;
