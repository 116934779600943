import RESUME from "../actions/resume";
import activities from "../data/ActivityByDays.json"
import bodys from "../data/BodyDays.json"
import calories from "../data/ResumeCaloriesDays.json"

export const requestResume = () => async (dispatch) => {
    dispatch({
        type: RESUME.LOAD,
    });

    var resumes = []
    for (let index = 0; index < activities["activities-calories"].length; index++) {
        const activity = activities["activities-calories"][index];
        const calorie = calories["foods-log-caloriesIn"][index];
        const body = bodys.weight.find(e => e.date == activity.dateTime)
        
        resumes.push({
            date: activity.dateTime,
            activity: activity,
            body: body ? body : null,
            calories: calorie,
        })
    }

    dispatch({
        type: RESUME.LOAD_SUCCESS,
        resume: resumes.reverse(),
        isError: false,
    });
};