import FOOD from "../actions/food";

const initalState = {
  data: [],
  isLoading: false,
  isError: false,
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case FOOD.LOAD:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case FOOD.LOAD_SUCCESS:
    return {
        ...state,
        data: action.foodDay,
        isLoading: false,
    };
    case FOOD.ERROR:
        return {
        ...state,
        data: action.usersData,
        isLoading: false,
        isError: true,
        };
    default:
      return state;
  }
};

export default reducer;