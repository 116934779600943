export const CALORIES = "calories"
export const CARBS = "carbs"
export const PROTEIN = "protein"
export const FAT = "fat"
export const FIBER = "fiber"
export const COLOR_CALORIES = "#8d6e63"
export const COLOR_CARBS = "#42a5f5"
export const COLOR_PROTEINS = "#f44336"
export const COLOR_FAT = "#ffc107"
export const COLOR_FIBER = "#7bb241"
export const TITLE_CARBS = "Glucides"
export const TITLE_PROTEINS = "Protéines"
export const TITLE_FAT = "Lipides"
export const TITLE_FIBER = "Fibres"
export const TITLE_CALORIES = "Calories"