import React from 'react';
import Meal from '../Meal/Meal';
import './FoodDay.css';


const FoodDay = props => {

    const {
        foods,
    } = props

    if (!foods || foods.length <= 0)
    return null

    const petitDej = foods.filter(food => food.loggedFood.mealTypeId == 1)
    const midi = foods.filter(food => food.loggedFood.mealTypeId == 3)
    const gouter = foods.filter(food => food.loggedFood.mealTypeId == 4)
    const soir = foods.filter(food => food.loggedFood.mealTypeId == 5)
    const apero = foods.filter(food => food.loggedFood.mealTypeId == 2)
    const autre = foods.filter(food => food.loggedFood.mealTypeId == 6)

    return (
        <div className="FoodDay">
            {petitDej.length > 0 && (
                <Meal title={"Petit Déjeuner"} foods={petitDej} />  
            )}
            {midi.length > 0 && (
                <Meal title={"Déjeuner"} foods={midi} />  
            )}
            {gouter.length > 0 && (
                <Meal title={"Gouter"} foods={gouter} />  
            )}
            {apero.length > 0 && (
                <Meal title={"Apéro"} foods={apero} />  
            )}
            {soir.length > 0 && (
                <Meal title={"Diner"} foods={soir} />  
            )}
            {autre.length > 0 && (
                <Meal title={"Autres Moments"} foods={autre} />  
            )}
        </div>
    )
}

export default FoodDay