import ACTIVITY from "../actions/activity";

const initalState = {
  data: [],
  isLoading: false,
  isError: false,
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case ACTIVITY.LOAD:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case ACTIVITY.LOAD_SUCCESS:
    return {
        ...state,
        data: action.activities,
        isLoading: false,
    };
    case ACTIVITY.ERROR:
        return {
        ...state,
        data: [],
        isLoading: false,
        };
    default:
      return state;
  }
};

export default reducer;