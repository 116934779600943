import SELECTED from "../actions/selected";

const initalState = {
  data: -1,
  isLoading: false,
  isError: false,
};

const reducer = (state = initalState, action) => {
  switch (action.type) {
    case SELECTED.LOAD:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case SELECTED.LOAD_SUCCESS:
    return {
        ...state,
        data: action.selected,
        isLoading: false,
    };
    case SELECTED.ERROR:
        return {
        ...state,
        data: 0,
        isLoading: false,
        isError: true,
        };
    default:
      return state;
  }
};

export default reducer;