import React from 'react';
import Pie from '../lib/pie';
import "./circle.css"


const Circle = props => {

    const {
        title,
        color,
        consumed,
        required,
    } = props

    const percentage = required != 0 ? (consumed / required) * 100 : 0

    return (
        <div className="Circle">
            <Pie percentage={percentage} colour={color} />
            <div className="Circle_title" style={{color: color}}>{title}</div>
            <div className="Circle_details">({consumed} grammes)</div>
        </div>
    )
}

export default Circle